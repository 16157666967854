import React from 'react'
import '../Styles/Loading.css'

const Loading = () => {
  return (
    <div className="spinner">
        <div className="loader1">
            <div className="loader2">
                <div className="loader3">

                </div>
            </div>
        </div>
        <h1>Loading............</h1>
</div>
  )
}

export default Loading